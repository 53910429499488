<template>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3' v-if='activeProjectDefault.isBreakfastVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class='flex justify-content-between mb-3'>
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Sabah Kahvaltısı</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ formatPrice(report.breakfastTotal)}} <span class='font-light ml-1'>&#8378;</span>
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round'
                         style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/kahvalti.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
                <Divider type='solid' />
                <span v-if='activeProjectDefault.isBreakfastOrderVisible && !isProjectReport'>
                    <span class='text-red-600 font-bold'>{{ formatQty(report.breakfastExpected)}}</span>
                    <span class='text-500'> siparişten </span>
                    <span class='text-green-600 font-bold'>{{ formatQty(report.breakfastActual)}}</span><span class='text-500'> servis yapıldı. </span>
                </span>
                <span class='text-gray-600 font-bold'>{{ formatQty(report.breakfastCalculated) }}</span><span class='text-500'> satış yapıldı.</span>
            </div>
        </div>

        <div class='col-12 md:col-6 lg:col-3' v-if='activeProjectDefault.isLunchVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class='flex justify-content-between mb-3'>
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Öğlen Yemeği</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ formatPrice(report.lunchTotal)}} <span class='font-light ml-1'>&#8378;</span>
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round'
                         style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/oglen_yemegi.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
                <Divider type='solid' />
                <span v-if='activeProjectDefault.isLunchOrderVisible && !isProjectReport'>
                    <span class='text-red-600 font-bold'>{{ formatQty(report.lunchExpected)}}</span>
                    <span class='text-500'> siparişten </span>
                    <span class='text-green-600 font-bold'>{{ formatQty(report.lunchActual) }}</span><span class='text-500'> servis yapıldı. </span>
                </span>
                <span class='text-gray-600 font-bold'>{{ formatQty(report.lunchCalculated) }}</span><span class='text-500'> satış yapıldı.</span>
            </div>
        </div>

        <div class='col-12 md:col-6 lg:col-3' v-if='activeProjectDefault.isDinnerVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class='flex justify-content-between mb-3'>
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Akşam Yemeği</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ formatPrice(report.dinnerTotal) }} <span class='font-light ml-1'>&#8378;</span>
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round'
                         style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/aksam_yemegi.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
                <Divider type='solid' />
                <span v-if='activeProjectDefault.isDinnerOrderVisible && !isProjectReport'>
                    <span class='text-red-600 font-bold'>{{ formatQty(report.dinnerExpected) }}</span>
                    <span class='text-500'> siparişten </span>
                    <span class='text-green-600 font-bold'>{{ formatQty(report.dinnerActual) }}</span><span class='text-500'> servis yapıldı. </span>
                </span>
                <span class='text-gray-600 font-bold'>{{ formatQty(report.dinnerCalculated) }}</span><span class='text-500'> satış yapıldı.</span>
            </div>
        </div>

        <div class='col-12 md:col-6 lg:col-3' v-if='activeProjectDefault.isNightMealVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class='flex justify-content-between mb-3'>
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Gece Yemeği</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ formatPrice(report.nightMealTotal) }} <span class='font-light ml-1'>&#8378;</span>
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round'
                         style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/gece.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
                <Divider type='solid' />
                <span v-if='activeProjectDefault.isNightMealOrderVisible && !isProjectReport'>
                    <span class='text-red-600 font-bold'>{{ formatQty(report.nightMealExpected) }}</span>
                    <span class='text-500'> siparişten </span>
                    <span class='text-green-600 font-bold'>{{ formatQty(report.nightMealActual) }}</span><span class='text-500'> servis yapıldı. </span>
                </span>
                <span class='text-gray-600 font-bold'>{{ formatQty(report.nightMealCalculated) }}</span><span class='text-500'> satış yapıldı.</span>
            </div>
        </div>

        <div class='col-12 md:col-6 lg:col-3' v-if='activeProjectDefault.isSnackVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class='flex justify-content-between mb-3'>
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Kumanya</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ formatPrice(report.snackTotal) }} <span class='font-light ml-1'>&#8378;</span>
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round'
                         style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/kumanya.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
                <Divider type='solid' />
                <span v-if='activeProjectDefault.isSnackOrderVisible && !isProjectReport'>
                    <span class='text-red-600 font-bold'>{{ formatQty(report.snackExpected) }}</span>
                    <span class='text-500'> siparişten </span>
                    <span class='text-green-600 font-bold'>{{ formatQty(report.snackActual) }}</span><span class='text-500'> servis yapıldı. </span>
                </span>
                <span class='text-gray-600 font-bold'>{{ formatQty(report.snackCalculated) }}</span><span class='text-500'> satış yapıldı.</span>
            </div>
        </div>

        <div class='col-12 md:col-6 lg:col-3' v-if='activeProjectDefault.isIftarVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class='flex justify-content-between mb-3'>
                    <div>
                        <span class='block font-medium mb-3 text-xl'>İftar</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ formatPrice(report.iftarTotal) }} <span class='font-light ml-1'>&#8378;</span>
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round'
                         style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/iftar.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
                <Divider type='solid' />
                <span v-if='activeProjectDefault.isIftarOrderVisible && !isProjectReport'>
                    <span class='text-red-600 font-bold'>{{ formatQty(report.iftarExpected) }}</span>
                    <span class='text-500'> siparişten </span>
                    <span class='text-green-600 font-bold'>{{ formatQty(report.iftarActual) }}</span><span class='text-500'> servis yapıldı. </span>
                </span>
                <span class='text-gray-600 font-bold'>{{ formatQty(report.iftarCalculated) }}</span><span class='text-500'> satış yapıldı.</span>
            </div>
        </div>

        <div class='col-12 md:col-6 lg:col-3' v-if='activeProjectDefault.isSahurVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class='flex justify-content-between mb-3'>
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Sahur</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ formatPrice(report.sahurTotal) }} <span class='font-light ml-1'>&#8378;</span>
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round'
                         style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/sahur.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
                <Divider type='solid' />
                <span v-if='activeProjectDefault.isSahurOrderVisible && !isProjectReport'>
                    <span class='text-red-600 font-bold'>{{ formatQty(report.sahurExpected) }}</span>
                    <span class='text-500'> siparişten </span>
                    <span class='text-green-600 font-bold'>{{ formatQty(report.sahurActual) }}</span><span class='text-500'> servis yapıldı. </span>
                </span>
                <span class='text-gray-600 font-bold'>{{ formatQty(report.sahurCalculated) }}</span><span class='text-500'> satış yapıldı.</span>
            </div>
        </div>

        <div class='col-12 md:col-6 lg:col-3'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class='flex justify-content-between mb-3'>
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Toplam Ciro</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ formatPrice(report.paymentTotal) }} <span class='font-light ml-1'>&#8378;</span><br>
                            <span class='text-red-600'>{{ formatPrice(report.paymentTotalVat) }} <span class='font-light ml-1'>&#8378; KDV Tutarı</span></span><br>
                            <span class='text-green-600'>{{ formatPrice(report.paymentTotal + report.paymentTotalVat) }} <span class='font-light ml-1'>&#8378; KDV Dahil</span></span>
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round'
                         style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/kasa.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
                <Divider type='solid' />
                <span class='text-red-600 font-bold'>{{ formatQty(report.totalExpected) }}</span>
                <span class='text-500'> siparişten </span>
                <span class='text-green-600 font-bold'>{{ formatQty(report.totalActual) }}</span>
                <span class='text-500'> servis yapıldı. </span>
                <span class='text-gray-600 font-bold'>{{ formatQty(report.totalCalculated) }}</span><span class='text-500'> satış yapıldı.</span>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'ReportTotals',
    props: {
        report: {},
        isProjectReport: {},
        activeProjectDefault:{},
    },
    methods: {
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatQty(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }

    }
};
</script>
