import { getData, getExcel, insertData } from './commonService';

export default class FoodPaymentService {


    #defaultUrl = "/api/Project";
    #defaultResourceName = "Firma";

    async getProjectFoodPayments(projectId, paymentDate) {
        return await getData(this.#defaultUrl + "/" + projectId + "/foodPayments?paymentDate=" + paymentDate);
    }

    async getProjectFoodReports(projectId, paymentDate) {
        return await getData(this.#defaultUrl + "/" + projectId + "/foodPayments/Report?paymentDate=" + paymentDate);
    }
    async getProjectFoodPaymentsForDailyReport(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + "/" + projectId + "/foodPayments/Daily?startDate=" + startDate + "&endDate="+ endDate);
    }
    async getProjectFoodPaymentsForMonthlyReport(projectId, startDate, endDate) {
        return await getData("/api/foodPayment/Monthly?projectId="+projectId+"&startDate=" + startDate + "&endDate="+ endDate);
    }


    async getProjectFoodPaymentsForReport(projectId, startDate, endDate, firmId) {
        return await getData(this.#defaultUrl + "/" + projectId + "/foodPayments?startDate=" + startDate + "&endDate="+ endDate+ "&firmId=" + firmId);
    }
    async getProjectFoodReportsForReport(projectId, startDate, endDate, firmId) {
        return await getData(this.#defaultUrl + "/" + projectId + "/foodPayments/Report?startDate=" + startDate + "&endDate="+ endDate + "&firmId=" + firmId );
    }

    async getProjectFoodReportsForDailyReport(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + "/" + projectId + "/foodPayments/Report?startDate=" + startDate + "&endDate="+ endDate);
    }


    async getProjectFoodPaymentsForDailyReportExcel(projectId, startDate, endDate) {
        return await getExcel(this.#defaultUrl + "/" + projectId + "/foodPayments/Daily/Excel?startDate=" + startDate + "&endDate="+ endDate);
    }

    async getProjectFoodPaymentsForMonthlyReportExcel(projectId, startDate, endDate) {
        return await getExcel("/api/foodPayment/Monthly/Excel?projectId="+projectId+"&startDate=" + startDate + "&endDate="+ endDate);
    }

    async getProjectFoodExcel(projectId, paymentDate) {
        return await getExcel(this.#defaultUrl + "/" + projectId + "/foodPayments/excelExport?paymentDate=" + paymentDate);
    }

    async getProjectFoodPaymentsForReportExcel(projectId, startDate, endDate, firmId) {
        return await getExcel(this.#defaultUrl + "/" + projectId + "/foodPayments/excel?startDate=" + startDate + "&endDate="+ endDate+ "&firmId=" + firmId);
    }

    async getProjectFoodPaymentsForReportExcel2(projectId, startDate, endDate, firmId) {
        return await getExcel(this.#defaultUrl + "/" + projectId + "/foodPayments/excel2?startDate=" + startDate + "&endDate="+ endDate+ "&firmId=" + firmId);
    }

    async calculateFoodPayment(dataItem) {
        const createData = {
            "projectId": dataItem.projectId,
            "paymentDate": dataItem.paymentDate,
            "managerUserId": dataItem.managerUserId,
        }
        return await insertData(this.#defaultUrl + "/" + dataItem.projectId + "/calculateFoodPayments", createData, this.#defaultResourceName);
    }

    async deleteFoodPayment(dataItem) {
        const createData = {
            "projectId": dataItem.projectId,
            "firmId": dataItem.firmId,
            "paymentDate": dataItem.paymentDate,
            "managerUserId": dataItem.managerUserId
        }
        return await insertData(this.#defaultUrl + "/" + dataItem.projectId + "/deleteFoodPayments", createData, this.#defaultResourceName);
    }

    async updateFoodPaymentPrices(dataItem) {
        const createData = {
            "projectId": dataItem.projectId,
            "paymentDate": dataItem.paymentDate,
            "managerUserId": dataItem.managerUserId
        }
        return await insertData(this.#defaultUrl + "/" + dataItem.projectId + "/updateFoodPaymentPrices", createData, this.#defaultResourceName);
    }

    async updateFoodPayment(dataItem) {
        const createData = {
            "projectId": dataItem.projectId,
            "firmId": dataItem.firmId,
            "paymentDate": dataItem.paymentDate,
            "managerUserId": dataItem.managerUserId,
            "breakfastActualValue": dataItem.breakfastActualValue,
            "lunchActualValue": dataItem.lunchActualValue,
            "dinnerActualValue": dataItem.dinnerActualValue,
            "snackActualValue": dataItem.snackActualValue,
            "iftarActualValue": dataItem.iftarActualValue,
            "sahurActualValue": dataItem.sahurActualValue,
            "nightMealActualValue": dataItem.nightMealActualValue,
            "breakfastExpectedValue": dataItem.breakfastExpectedValue,
            "lunchExpectedValue": dataItem.lunchExpectedValue,
            "dinnerExpectedValue": dataItem.dinnerExpectedValue,
            "snackExpectedValue": dataItem.snackExpectedValue,
            "iftarExpectedValue": dataItem.iftarExpectedValue,
            "sahurExpectedValue": dataItem.sahurExpectedValue,
            "nightMealExpectedValue": dataItem.nightMealExpectedValue,
            "isProcessed" : dataItem.isProcessed
        }
        return await insertData(this.#defaultUrl + "/" + dataItem.projectId + "/updateFoodPayments", createData, this.#defaultResourceName);
    }


}